import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_OneYearRule = () => {
    const seo = {
        metaDesc: '9 Important Elements of A Provisional Patents - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="9 Important Elements of A Provisional Patents - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>9 Important Elements of A Provisional Patents</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>9 Important Elements of A Provisional Patents</h2>
                                <ol>
                                    <li  className='h3'>
                                        <h3><b><i> title</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The title is a short description of your invention. It should be less than 500
                                    characters.</span></p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li  className='h3'>
                                        <h3><b><i> abstract</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The abstract enables readers to determine quickly the nature of the technical
                                    disclosures of the invention.&nbsp; It is generally limited to a single paragraph and should not be longer
                                    than 150 words.</span></p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li  className='h3'>
                                        <h3><b><i> drawings</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Drawings should be done prior to drafting the detailed description because they
                                    will help you draft those texts in a logical order.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>According to the USPTO, you should provide drawings where necessary for the
                                    understanding of the invention. The drawings must be filed with the application.&nbsp; They can include flow
                                    sheets in cases of processes, and diagrammatic views for mechanical or electrical
                                    inventions.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>For a PPA, you may submit informal drawings or photographs.&nbsp; However, for a
                                    utility patent application, the USPTO has established certain requirements for drawings.</span></p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li  className='h3'>
                                        <h3><b><i> description</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>While the PPA is not checked for sufficiency of disclosure or enablement, you
                                    should provide a thorough, thoughtful disclosure that includes how to implement–construct and use–your
                                    invention. The disclosure should be more than just formulating a desire to accomplish something–that is
                                    called wishing, not inventing.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>It should be sufficient to allow one of ordinary skill in the art to construct a
                                    product or perform a process with unduly extensive research or experimentation.&nbsp; You should teach
                                    specific, concrete ways of achieving a desired result. Writing details will show the USPTO that you have a
                                    definite idea of the complete and operative invention.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>If there is more than one way to operate or build your invention, you should
                                    write down the best mode for constructing your invention and detail the best way to use it.&nbsp; If you do
                                    not do this and later file for a utility patent, your application may fail.&nbsp; Any additional functions
                                    should also be noted, but they should be described succinctly.</span></p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li className='h3'>
                                        <h3><b><i> prior art</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>To satisfy the novelty element of the patent application process, you should note
                                    any discoveries of relevant prior art and how your invention differs.&nbsp; It’s helpful to also describe
                                    what advantages your invention has in relation to any prior art.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li  className='h3'>
                                        <h3><b><i> inventor</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>It can be tricky to determine the inventorship of a provisional application.
                                    Again, U.S. law generally regards any individual who contributed to the conception of an invention as
                                    recited in one or more claims of a patent application as an inventor. However, there is no requirement for
                                    claims in a PPA. If your PPA does not have claims, determining the inventor can be hard.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>It might be helpful to know that some lawyers determine inventorship based on the
                                    invention as described in the PPA’s specification.&nbsp; Using the specification, they’ve concluded that
                                    anyone who contributed to the PPA’s description and drawings may be listed as an inventor. On the other
                                    hand, anyone who contributes only lab or assembly labor, supervision of routine techniques, or other
                                    non-mental contributions, should not be considered an inventor.</span></p>
                                <ol start="7">
                                    <li  className='h3'>
                                        <h3><b><i> date</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Although the need for determining the date of invention will be eliminated when
                                    AIA becomes effective, it is still an interesting exercise. The invention date is usually the conception
                                    date, when the inventor first conceives of the invention. That’s especially supported as the invention date
                                    if you diligently work on the invention, otherwise known as reducing it to practice. You can reduce your
                                    invention to practice by building a prototype or filing a patent application.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Again, AIA renders this process moot as the invention date is simply the filing
                                    date. Thus, inventors who quickly file a quality patent application will benefit from AIA’s change.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="8">
                                    <li  className='h3'>
                                        <h3><b><i> multiple inventions</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You can include multiple invention disclosures in one PPA. The USPTO charges
                                    extra fees if you exceed a certain number of pages in your applications, however.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol start="9">
                                    <li  className='h3'>
                                        <h3><b><i> fees</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You should pay the filing fees when you submit your PPA.&nbsp; If you don’t, the
                                    USPTO will send you a notice with a deadline to respond. You have up to six month from the mailing date to
                                    respond with late fees. If you miss the deadline and file for a utility patent later, then you cannot claim
                                    the benefit of the earlier PPA filing date. Consequently, you lose valuable patent
                                    rights.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>For example, if your invention is “in use” or “on sale” in the United States
                                    after you’ve filed a PPA but you fails to timely convert the PPA into a utility application within the
                                    twelve month period, you may lose the right to ever patent the invention.&nbsp; That’s because your
                                    inability to claim priority to the PPA would leave the use/sale of the invention as prior art against your
                                    own subsequent utility patent application.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_OneYearRule;